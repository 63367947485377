import { Helmet } from 'react-helmet-async';
import { ReactNode } from 'react';

type PageProps = {
  children: ReactNode;
  title: string;
  meta?: ReactNode;
  other?: any;
} & typeof defaultProps;

const defaultProps = {
  meta: undefined,
  other: undefined,
};

export default function Page({ children, title, meta, ...other }: PageProps) {
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="author" content="RiziHealth" />
        <meta
          name="description"
          content="An easier way to solicit or donate funds for healthcare"
        />
        <meta
          name="keywords"
          content="rizihealth, rizi health, health care, donate, primary, africa, affordable, paid, donate funds, healthcare, healthy, charitable, health, care, solicit, donate funds for healthcare, fundraising, crowdfunding, fundraise, medical, future, RiziHealth donations in action, register, access, specialist, live, virtually"
        />
        <meta property="og:title" content="Rizi Health" />
        <meta property="og:site_name" content="rizihealth" />
        <meta property="og:url" content="https://rizihealth.com/" />
        <meta
          property="og:description"
          content="An easier way to solicit or donate funds for healthcare"
        />
        <meta property="og:type" content="website" />
        <meta property="og:rich_attachment" content="true" />
        <meta
          property="og:image"
          content="https://rizihealth.com/assets/social/rizihealth-social-headertag.png"
        />
        <meta name="twitter:title" content="Rizi Health" />
        <meta name="twitter:creator" content="Rizihealth" />
        <meta name="twitter:site" content="@rizihealth" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:image"
          content="https://rizihealth.com/assets/social/rizihealth-social-headertag.png"
        />
        <meta
          name="twitter:image:alt"
          content="An easier way to solicit or donate funds for healthcare"
        />
        <meta
          name="twitter:description"
          content="An easier way to solicit or donate funds for healthcare"
        />
        {meta}
      </Helmet>
      <div {...other}>{children}</div>
    </>
  );
}

Page.defaultProps = defaultProps;
