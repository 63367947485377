export type ImageName = keyof typeof images;

const images = {
  logo: 'assets/logo.svg',
  logoDark: 'assets/rizi-logo-dark.svg',
  'vector-1': 'assets/vectors/vector-one.svg',
  'blog-1': 'assets/bg/blog-1.svg',
  'blog-2': 'assets/bg/blog-2.svg',
  'blog-3': 'assets/bg/blog-3.svg',
  'silver-sphere': 'assets/vectors/silvershpere.svg',
  slides: {
    'slide-1':
      'https://images.unsplash.com/photo-1521510186458-bbbda7aef46b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&h=330&q=80',
    'slide-2':
      'https://images.unsplash.com/photo-1507153030234-657ffc3cf24a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&h=330&q=80',
    'slide-3':
      'https://images.unsplash.com/photo-1571442463800-1337d7af9d2f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&h=330&q=80',
    'slide-4':
      'https://images.unsplash.com/photo-1596510914841-40223e421e29?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&h=330&q=80',
    'slide-5':
      'https://images.unsplash.com/photo-1509099863731-ef4bff19e808?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&h=330&q=80',
    'slide-6':
      'https://images.unsplash.com/photo-1517486808906-6ca8b3f04846?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&h=330&q=80',
    'slide-7':
      'https://images.unsplash.com/photo-1556484687-30636164638b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&h=330&q=80',
  },
};

export default images;
