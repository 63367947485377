import { ReactNode, AnchorHTMLAttributes, FC } from 'react';
import { Link } from 'react-router-dom';

interface CustomLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  to: string;
  children: ReactNode;
}

const CustomLink: FC<CustomLinkProps> = ({ to, children, ...rest }) => {
  return (
    <Link {...rest} to={to}>
      {children}
    </Link>
  );
};

export default CustomLink;
