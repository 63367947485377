import Page from 'components/Page';
import Footer from 'components/Footer';
import Nav from 'components/Nav';
import { lazy, useState } from 'react';

const Home = lazy(() => import('pages/Home'));
const Join = lazy(() => import('pages/join'));

function Pages() {
  const [showRegisterForm, setShowRegisterForm] = useState(false);
  const [registered, setRegistered] = useState(false);

  const changeView = () => {
    setShowRegisterForm(true);
  };

  const handleRegister = () => {
    setRegistered(true);
    setShowRegisterForm(false);
  };
  return (
    <div className="w-full h-full bg-theme-green-100">
      <Nav
        registered={registered}
        showed={showRegisterForm}
        onClick={changeView}
      />
      <main>
        {!showRegisterForm ? (
          <Home onClick={changeView} registered={registered} />
        ) : (
          <Page title="Join">
            <Join handleRegister={handleRegister} />
          </Page>
        )}
      </main>
      <Footer />
    </div>
  );
}

export default Pages;
