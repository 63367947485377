import { useRoutes } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import Pages from 'pages';

const Loader = lazy(() => import('components/Loader'));
const NotFound = lazy(() => import('pages/notfound'));

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: (
        <Suspense fallback={<Loader />}>
          <Pages />
        </Suspense>
      ),
    },
    {
      path: '*',
      element: (
        <Suspense fallback={<Loader />}>
          <NotFound />
        </Suspense>
      ),
    },
  ]);
}
