import images from 'utils/images';
import { SectionProps } from 'utils/interface';

export default function Nav({ registered, showed, onClick }: SectionProps) {
  return (
    <nav className="container absolute bg-transparent inset-x-0 z-30 px-4 py-6 mx-auto flex justify-between items-center">
      <div className="flex items-center justify-between">
        <a href="/">
          <img
            src={images.logo}
            width="90px"
            height="36px"
            className=" h-6 lg:h-9"
            alt="logo"
          />
        </a>
      </div>
      {!registered && showed !== true && (
        <a href="/#join">
          <button
            type="button"
            className="block h-10 px-6 py-2 bg-theme-dark shadow-shadow-light rounded-2xl text-center text-base text-theme-light font-eina01 font-medium leading-[165.3%] transition-colors transform duration-300"
            onClick={onClick}
          >
            Join Waitlist
          </button>
        </a>
      )}
    </nav>
  );
}
