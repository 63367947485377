import images from 'utils/images';
import Icon from './icon';
import CustomLink from './Link/inddex';

export default function Footer() {
  const date = `${new Date().getFullYear()}`;

  return (
    <footer className="w-full bg-theme-dark px-7 py-11 lg:p-16 mx-auto mb-0">
      <div className="flex flex-col md:flex-row items-start justify-between">
        <CustomLink to="/">
          <img
            src={images.logoDark}
            width="90px"
            height="36px"
            alt="rizi-logo"
            loading="lazy"
          />
        </CustomLink>

        <div className="order-2 md:order-1 mt-14 md:my-0">
          <hr className="md:hidden w-full text-theme-light px-10 pb-4 mx-auto" />
          <p className="text-sm font-inter leading-[27px] text-theme-gray-200 text-center lg:text-right">
            © Copyright {date}, All rights reserved.
          </p>
        </div>
        <div className="md:order-2 pt-10 md:pt-0">
          <h1 className="md:hidden font-p22Mackinac font-normal text-lg text-theme-light leading-[27px] mt-4 lg:mt-0 mb-6">
            Socials
          </h1>
          <div className="flex flex-row items-center pt-4 md:pt-0 space-x-4">
            <a
              href="https://twitter.com/rizihealth?s=21&t=jfqSe8-otdIq4QCEG5cAhg"
              target="_blank"
              rel="noreferrer"
            >
              <Icon.TwitterIcon className="w-10 h-10 text-white bg-[#2F3034] rounded-full p-2 hover:text-theme-secondary-alt ease-in duration-300" />
            </a>
            <a
              href="https://www.facebook.com/Rizi-health-105023955559434/"
              target="_blank"
              rel="noreferrer"
            >
              <Icon.FacebookIcon className="w-10 h-10 text-white bg-[#2F3034] rounded-full p-2 hover:text-theme-secondary-alt ease-in duration-300" />
            </a>
            <a
              href="https://instagram.com/rizihealth?igshid=YmMyMTA2M2Y="
              target="_blank"
              rel="noreferrer"
            >
              <Icon.InstagramIcon className="w-10 h-10 text-white bg-[#2F3034] rounded-full p-2 hover:text-theme-secondary-alt ease-in duration-300" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
